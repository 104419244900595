<template>
  <form @submit.prevent="send">
    <p class="has-text-weight-bold is-size-4 mb-4">
      {{ $t("af:prepayment.form.title") }}
    </p>
    <p class="mb-6">
      {{ $t("af:prepayment.form.subtitle") }}
    </p>
    <validation-observer ref="observer">
      <b-field grouped class="mt-4">
        <BInputWithValidation
          v-model="formData.lastName"
          rules="required"
          :label="$t('af:prepayment.form.input.last_name.label')"
          label-position="on-border"
          class="mr-3 is-half"
          :allow-space="true"
        />
        <BInputWithValidation
          v-model="formData.firstName"
          rules="required"
          :label="$t('af:prepayment.form.input.first_name.label')"
          label-position="on-border"
          class="is-half"
          :allow-space="true"
        />
      </b-field>

      <b-field>
        <b-radio
          v-model="selectedIdentifier"
          native-value="bankAccount"
          type="is-info"
          >{{
            $t("af:prepayment.form.input.bank_account_number.label")
          }}</b-radio
        >
        <b-radio
          v-model="selectedIdentifier"
          native-value="loanNumber"
          type="is-info"
          >{{ $t("af:prepayment.form.input.loan_number.label") }}</b-radio
        >
      </b-field>

      <b-field v-if="selectedIdentifier === 'bankAccount'" class="mt-4">
        <BankAccountNumberInput
          v-model="formData.bankAccountNumber"
          :label="$t('af:prepayment.form.input.bank_account_number.label')"
          :required="selectedIdentifier === 'bankAccount'"
        />
      </b-field>
      <b-field v-if="selectedIdentifier === 'loanNumber'" class="mt-4">
        <BInputWithValidation
          v-model="formData.loanNumber"
          :rules="{
            required: selectedIdentifier === 'loanNumber',
            regex: /^P73(?:([0-9])(?!\1{8}$)){9}$/,
          }"
          :label="$t('af:prepayment.form.input.loan_number.label')"
          label-position="on-border"
          :allow-space="false"
        />
      </b-field>

      <phone-input
        :label="$t('af:prepayment.form.input.phone.label')"
        v-model="formData.phone"
      />

      <BInputWithValidation
        v-model="formData.email"
        rules="required|email"
        :label="$t('af:prepayment.form.input.email.label')"
        label-position="on-border"
        field-class="mb-5"
      />

      <ValidationProvider
        mode="lazy"
        :rules="{
          required: true,
        }"
        v-slot="{ errors, validated, valid }"
      >
        <b-field
          class="mb-5"
          :label="$t('af:prepayment.form.input.day.label')"
          label-position="on-border"
          expanded
          :type="{
            'is-danger': errors[0],
            'is-success': validated && valid,
          }"
          :message="errors"
        >
          <b-datepicker
            v-model="formData.preferredDate"
            locale="hu-HU"
            icon-pack="fa"
            icon="calendar"
            trap-focus
            position="is-top-right"
            :mobile-native="false"
            :min-date="yesterday"
            :years-range="[-2, 2]"
          />
        </b-field>
      </ValidationProvider>

      <b-field
        :label="$t('af:prepayment.form.input.user_comment.label')"
        label-position="on-border"
        class="mb-2"
      >
        <b-input
          maxlength="2000"
          type="textarea"
          v-model="formData.userComment"
          custom-class="call-me-back-textarea"
        />
      </b-field>

      <statements-acceptance
        :statements="statements"
        layout-type="PREPAYMENT"
        read-required-validation-message="af:prepayment.form.acceptance.message.validation.statement_read_required"
        ref="statementAcceptances"
        :is-public="true"
      />

      <p class="has-text-weight-bold mt-3">
        <b-button
          type="is-blue"
          :value="$t('af:prepayment.form.button.send')"
          tag="input"
          @click="send"
        ></b-button>
      </p>
    </validation-observer>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "../../plugins/i18n";
import BankAccountNumberInput from "@/components/BankAccountNumberInput.vue";

export default {
  name: "PrepaymentForm",
  components: {
    BankAccountNumberInput,
    BInputWithValidation: () => import("@/components/BInputWithValidation.vue"),
    StatementsAcceptance: () => import("@/components/StatementsAcceptance.vue"),
    PhoneInput: () => import("@/components/PhoneInput.vue"),
  },
  props: {},
  data() {
    return {
      formData: {
        lastName: null,
        firstName: null,
        phone: "",
        email: null,
        date: null,
        bankAccountNumber: null,
        loanNumber: null,
        userComment: null,
      },
      statements: null,
      selectedIdentifier: "bankAccount",
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
    }),
    yesterday() {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return today;
    },
  },
  async mounted() {
    await this.$store.dispatch("prepayment/acceptanceStatements");
    this.statements = this.$store.getters["prepayment/acceptances"];

    this.formData.date = new Date();

    if (this.loggedIn) {
      await this.$store.dispatch("profile/fetch");
      let customer = this.$store.getters["profile/get"];
      if (customer.lastName) {
        this.formData.lastName = customer.lastName;
        this.formData.firstName = customer.firstName;
      }

      if (customer.phone) {
        this.formData.phone = customer.phone;
      }

      if (customer.email) {
        this.formData.email = customer.email;
      }
    }
  },
  methods: {
    async send() {
      let valid = await this.$refs.observer.validate();
      let statementsValid = await this.$refs.statementAcceptances.validate();

      if (!valid || !statementsValid) return;

      this.formData.preferredDate.setDate(
        this.formData.preferredDate.getDate() + 1
      );

      try {
        if (this.loggedIn) {
          await this.$store.dispatch("prepayment/create", this.formData);
        } else {
          await this.$store.dispatch("prepayment/createPublic", this.formData);
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:prepayment.form.message.send_success"),
          position: "is-bottom",
          type: "is-success",
        });
        if (this.loggedIn) {
          await this.$router.push("/user/profile");
        } else {
          this.$emit("prepayment-sent");
        }
      } catch (ex) {
        this.$buefy.toast.open({
          duration: 3000,
          message: i18n.t("af:prepayment.form.message.send_failed"),
          position: "is-bottom",
          type: "is-danger",
        });
        console.error(ex);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.is-half {
  width: 50%;
}

.button {
  padding-left: 3rem;
  padding-right: 3rem;
}

.button.is-text {
  text-decoration: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.button.is-text {
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    text-decoration: underline;
    background-color: white;
  }
}
</style>

<style lang="scss">
.time-select {
  select {
    border-radius: 8px !important;
  }
}

.call-me-back-textarea {
  min-height: 6rem !important;
}
</style>
