var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"mode":"lazy","rules":{
    regex: /^[0-9]{8}-[0-9]{8}(-[0-9]{8})?$/,
    required: _vm.required,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var validated = ref.validated;
  var valid = ref.valid;
return [_c('b-field',_vm._b({staticClass:"validator-field",class:_vm.fieldClass,attrs:{"type":{
      'is-danger': errors[0],
      'is-success': validated && valid,
    },"label-position":"on-border","message":errors,"label":_vm.label}},'b-field',_vm.$attrs,false),[_c('b-input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('########-########-########'),expression:"'########-########-########'"}],staticClass:"phone-input",attrs:{"type":"tel","inputmode":"numeric"},model:{value:(_vm.formattedValue),callback:function ($$v) {_vm.formattedValue=$$v},expression:"formattedValue"}},'b-input',_vm.$attrs,false))],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }