<template>
  <ValidationProvider
    mode="lazy"
    :rules="{
      regex: /^[0-9]{8}-[0-9]{8}(-[0-9]{8})?$/,
      required: required,
    }"
    v-slot="{ errors, validated, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{
        'is-danger': errors[0],
        'is-success': validated && valid,
      }"
      label-position="on-border"
      :message="errors"
      class="validator-field"
      :class="fieldClass"
      :label="label"
    >
      <b-input
        v-model="formattedValue"
        type="tel"
        inputmode="numeric"
        v-bind="$attrs"
        v-mask="'########-########-########'"
        class="phone-input"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    vid: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    fieldClass: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    formattedValue() {
      this.$emit("input", this.formattedValue);
    },
    value: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          if (newVal.length > 27) {
            newVal = newVal.substring(0, 27);
          }
        }
        this.formattedValue = newVal;
      },
    },
  },
  methods: {
    forceUpdate(value) {
      this.innerValue = value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.phone-input-group {
  display: flex;

  .phone-input {
    order: 2;
    width: 100%;

    input {
      width: 100%;
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
    }
  }

  .phone-prefix {
    order: 1;
    width: 3.5rem;

    .control {
      .input {
        width: 100%;
        padding-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
        border-right: none;
        border-color: $grey-lightest;

        &.is-danger {
          border-color: #f14668;
        }

        &.is-success {
          border-color: #48c78e;
        }
      }
    }

    .icon.is-right {
      display: none;
    }
  }
}
</style>
